<template>
  <div id="tech_sup">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :bgcolor="BannerKvData.bgcolor"
      :backgroundPic="BannerKvData.backgroundPic"
      :backgroundPicRwd="BannerKvData.backgroundPicRwd"/>
    <div class="inner w800">
      <p class="small txt-theme">
        歡迎您透過表單聯絡我們的技術支援部門，讓我們提供及時的協助您。
      </p>

      <div>
        <p class="small">
          E-mail或手機*
        </p>
        <input type="text"
          v-model="contact.contactType"
          placeholder="請輸入email或手機">
      </div>

      <div>
        <p class="small">
          諮詢內容*
        </p>
        <textarea name="" id="" cols="30" rows="10"
          v-model="contact.contactInfo"
          placeholder="請輸入諮詢內容">
        </textarea>
      </div>

      <div>
        <button type="button" class="btn"
          @click="clickSubmit();">
          送出表單
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerKv from '@/components/BannerKv.vue';
import pic01 from '@/assets/img/about_nuwa_bn_web.jpg';
import pic02 from '@/assets/img/about_nuwa_bn_mob.jpg';
import { createSupport } from '@/lib/public';

export default {
  name: 'Privacy',
  components: {
    BannerKv,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'TECHNICAL SUPPORT',
        titleZh: '技術支援',
        bgcolor: '#fff',
        backgroundPic: pic01,
        backgroundPicRwd: pic02,
      },
      contact: {
        contactType: '',
        contactInfo: '',
      },
    };
  },
  methods: {
    clickSubmit() {
      if (
        this.contact.contactType === ''
        || this.contact.contactInfo === ''
      ) {
        this.$customSWAL({ icon: 'error', title: '請填寫完整資料' });
      } else {
        createSupport(
          {
            contact_info: this.contact.contactType,
            question: this.contact.contactInfo,
          },
          (message) => {
            this.$customSWAL({ title: message });
          },
          (message) => {
            this.$customSWAL({ icon: 'error', title: message });
          },
        );
      }
    },
  },
};
</script>
